import React, { useState, useEffect } from 'react';
import LuseskjortTabell from '../../components/LuseSkjortTabell/LuseSkjortTabell';




const Luseskjort = () => {
  const [nummer, setNummer] = useState('');
  const [luseskjort, setLuseskjort] = useState(null);


  const handleSok = async () => {
    try {
      const response = await fetch(`/api/luseskjort/sok/${nummer}`);
      if (!response.ok) {
        throw new Error(`HTTP-feil: Status ${response.status}`);
      }
      const data = await response.json();
      setLuseskjort(data);
    } catch (error) {
      console.error('Feil ved søk', error);
      // Håndter eventuelle feil, f.eks. vise en feilmelding
    }
  };

  return (
    
    <div className="container">
      <h1>Luseskjørt</h1>
      <LuseskjortTabell  />
    </div>
    
  );
};

export default Luseskjort;
