import React from 'react';

const HomePage = () => {
  return (
    <div className="container">
      <header>
        <h1>Velkommen til Luseskjørt-Spesialistene</h1>
        <p>Din partner for alt relatert til luseskjørt i havbruk!</p>
      </header>
      
      <section>
        <h2>Hva Vi Gjør</h2>
        <p>Vi er spesialister på behandling og vedlikehold av luseskjørt for havbruksnæringen. Vårt team sørger for at dine luseskjørt alltid er i topp stand, klar til bruk.</p>
      </section>
      
      <section>
        <h2>Våre Tjenester</h2>
        <ul>
          <li>Vask og Rengjøring av Luseskjørt</li>
          <li>Reparasjon og Vedlikehold</li>
          <li>Kvalitetskontroll og Inspeksjon</li>
          <li>Kassering og Resirkulering av Gamle Luseskjørt</li>
        </ul>
      </section>

      <section>
        <h2>Hvorfor Velge Oss?</h2>
        <p>Med årevis av erfaring i bransjen, bruker vi de nyeste metodene og teknologiene for å sikre at dine luseskjørt holder høyeste standarder. Vår tilnærming garanterer lang levetid og maksimal effektivitet.</p>
      </section>

      <footer>
        <p>Kontakt oss for mer informasjon eller for å avtale en konsultasjon.</p>
      </footer>
    </div>
  );
};

export default HomePage;
