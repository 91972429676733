import React, { useState } from 'react';
import './LuseSkjortTabell.css'; // Sørg for at denne CSS-filen eksisterer
import { TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

function createData(nummer, aargang, tilstand, vasket, reparert, kassert, inneNa, lengde, history) {
  return {
    nummer,
    aargang,
    tilstand,
    vasket,
    reparert,
    kassert,
    inneNa,
    lengde,
    history
  };
}

const initialRows = [
  createData('123456', 2021, 'Ok', '✓', 'X', 'X', true, '110x10', [
    { date: '2020-01-05', action: 'Vasket' },
    { date: '2020-01-12', action: 'Reparert' },
  ]),
  createData('123457', 2020, 'God', '✓', 'X', 'X', false, '170x10', [
    { date: '2020-01-05', action: 'Vasket' },
    { date: '2020-01-12', action: 'Reparert' },
  ]),
  createData('154', 2021, 'Ok', '✓', 'X', 'X', false, '110x10', [
    { date: '2020-01-05', action: 'Vasket' },
    { date: '2020-01-12', action: 'Reparert' },
  ]),
  createData('84618', 2020, 'Kassert', '✓', 'X', '✓', false, '170x10', [
    { date: '2020-01-05', action: 'Kassert' },
    { date: '2020-01-05', action: 'Vasket' },
    { date: '2020-01-12', action: 'Reparert' },
  ]),
  // ... flere luseskjørt objekter ...
];

function Row({ row }) {
  const [open, setOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [selectedAction, setSelectedAction] = useState('');

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleConfirmDialogOpen = (action) => {
    setSelectedAction(action);
    setConfirmDialogOpen(true);
  };

  const handleConfirmDialogClose = () => {
    setConfirmDialogOpen(false);
  };

  const confirmAction = () => {
    console.log(`${selectedAction} utført på luseskjørt nummer ${row.nummer}`);
    // Implementer logikken for å håndtere de forskjellige handlingene her
    setConfirmDialogOpen(false);
    setDialogOpen(false);
  };

  const rowStyle = {
    backgroundColor: row.kassert === '✓' ? '#ffcccc' :
                      row.inneNa ? '#ccffcc' : 
                      'none'
  };

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} style={rowStyle}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row" onClick={handleDialogOpen} style={{ cursor: 'pointer' }}>
        {row.nummer}
      </TableCell>
        <TableCell align="right">{row.aargang}</TableCell>
        <TableCell align="right">{row.tilstand}</TableCell>
        <TableCell align="right">{row.vasket}</TableCell>
        <TableCell align="right">{row.reparert}</TableCell>
        <TableCell align="right">{row.kassert}</TableCell>
        <TableCell align="right">{row.lengde}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Historikk
              </Typography>
              <Table size="small" aria-label="history">
                <TableHead>
                  <TableRow>
                    <TableCell>Dato</TableCell>
                    <TableCell>Handling</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.history.map((historyRow) => (
                    <TableRow key={historyRow.date}>
                      <TableCell component="th" scope="row">{historyRow.date}</TableCell>
                      <TableCell>{historyRow.action}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      <Dialog open={dialogOpen} onClose={handleDialogClose}>
      <DialogTitle>{`Handling for luseskjørt nummer ${row.nummer}`}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Velg om du vil vaske, reparere eller kassere luseskjørtet.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
          <Button onClick={() => handleConfirmDialogOpen('Vaske')}>Vaske</Button>
          <Button onClick={() => handleConfirmDialogOpen('Reparere')}>Reparere</Button>
          <Button onClick={() => handleConfirmDialogOpen('Kassere')} color="secondary">Kassere</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={confirmDialogOpen} onClose={handleConfirmDialogClose}>
        <DialogTitle>{`Er du sikker på at du vil ${selectedAction} dette luseskjørtet?`}</DialogTitle>
        <DialogActions>
          <Button onClick={handleConfirmDialogClose}>Nei</Button>
          <Button onClick={confirmAction} color="primary">Ja</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    nummer: PropTypes.string.isRequired,
    aargang: PropTypes.number.isRequired,
    tilstand: PropTypes.string.isRequired,
    vasket: PropTypes.string.isRequired,
    reparert: PropTypes.string.isRequired,
    kassert: PropTypes.string.isRequired,
    inneNa: PropTypes.bool.isRequired,
    lengde: PropTypes.string.isRequired,
    history: PropTypes.arrayOf(
      PropTypes.shape({
        date: PropTypes.string.isRequired,
        action: PropTypes.string.isRequired,
      }),
    ).isRequired,
  }).isRequired,
};

export default function LuseSkjortTabell() {
  const [search, setSearch] = useState('');
  const [sokteSkjorter, setSokteSkjorter] = useState(initialRows);

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
    const filtrerteSkjorter = initialRows.filter((skjort) =>
      skjort.nummer.toLowerCase().includes(event.target.value.toLowerCase())
    );
    setSokteSkjorter(filtrerteSkjorter);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <TextField 
        label="Søk etter nummer" 
        variant="outlined" 
        value={search} 
        onChange={handleSearchChange}
        fullWidth
        sx={{ marginBottom: 2 }}
      />
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Nummer</TableCell>
              <TableCell align="right">Årgang</TableCell>
              <TableCell align="right">Tilstand</TableCell>
              <TableCell align="right">Vasket</TableCell>
              <TableCell align="right">Reparert</TableCell>
              <TableCell align="right">Kassert</TableCell>
              <TableCell align="right">Lengde</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sokteSkjorter.map((row) => (
              <Row key={row.nummer} row={row} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
