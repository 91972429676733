import React from 'react';
import { Box, Container, Typography, Link } from '@mui/material';
import SetMealIcon from '@mui/icons-material/SetMeal';
import './Footer.css'; // Importer din CSS-fil her

const Footer = () => {
  return (
    <Box
      component="footer"
      sx={{
        py: 3,
        px: 2,
        mt: 'auto',
        backgroundColor: (theme) =>
          theme.palette.mode === 'light'
            ? theme.palette.grey[200]
            : theme.palette.grey[800],
      }}
    >
      <Container maxWidth="xl">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box display="flex" alignItems="center">
            <SetMealIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} />
            <Typography variant="h6" color="inherit" component="div">
              Pundslett Bygg & Base /v Stig A. Martinsen
            </Typography>
          </Box>
          <Typography variant="body1" color="inherit" component="div">
            &copy; {new Date().getFullYear()} Pundslett Bygg & Base. Alle rettigheter forbeholdt.
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
