import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import NavBar from './components/NavBar/NavBar';
import Luseskjort from './pages/LuseSkjort/LuseSkjort';
import HomePage from './pages/Home/Home';
import Footer from './components/Footer/Footer';
import './App.css'; // Sørg for at denne importsetningen er til stede

//import HomePage from './pages/HomePage/HomePage';
//import LuseskjortVask from './pages/LuseskjortVask/LuseskjortVask';
//import LuseskjortReparasjon from './pages/LuseskjortReparasjon/LuseskjortReparasjon';
//import Dashboard from './pages/Dashboard/Dashboard';

const App = () => {
  return (
    <Router>
      <div className="app-container">
        <NavBar />
        <div className="app-content">
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/luseskjørt" element={<Luseskjort />} />
            {/* ... andre ruter ... */}
          </Routes>
        </div>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
